import React from 'react';
import { ContainerOutlined, FormOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Button, Modal, Steps, Form } from 'antd';
import StepDetalleCompra from './StepDetalleCompra';
import StepDireccionEnvio from './StepDireccionEnvio';
import StepConfirmarOrden from './StepConfirmarOrden';

function ModalForm({
  open,
  currentStep,
  onCancel,
  onCreate,
  form,
  art,
  next,
  prev,
  nextValidateForm,
}) {
  const stepStyle = {
    marginBottom: 20,
    boxShadow: '0px -1px 0 0 #e8e8e8 inset',
  };
  const { Step } = Steps;
  const steps = [
    { title: 'Detalle de compra', icon: 'shopping-cart' },
    { title: 'Dirección de envío ', icon: 'container' },
    { title: 'Confirmar orden', icon: 'form' },
  ];

  return (
    <Modal
      open={open}
      title="Comprar"
      okText="Create"
      onCancel={onCancel}
      className="shop-modal-width"
      centered
      footer={[
        <div className="steps-action">
          {currentStep > 0 && (
            <Button style={{ marginLeft: 8 }} onClick={prev}>
              Anterior
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => (currentStep === 1 ? nextValidateForm() : next())}
            >
              Siguiente
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" htmlType="submit" form="order">
              Realizar pedido
            </Button>
          )}
        </div>,
      ]}
    >
      <Steps current={currentStep} direction="horizontal" style={stepStyle} type="navigation">
        <Step key="Detalle de compra" title="Detalle de compra" icon={<ShoppingCartOutlined />} />
        <Step key="Dirección de envío" title="Dirección de envío" icon={<ContainerOutlined />} />
        <Step key="Confirmar orden" title="Confirmar orden" icon={<FormOutlined />} />
      </Steps>
      <Form
        name="form_in_modal"
        className="mx-auto"
        onFinish={onCreate}
        data-netlify="true"
        id="order"
        form={form}
      >
        <StepDetalleCompra art={art} currentStep={currentStep} />
        <StepDireccionEnvio onCreate={onCreate} form={form} currentStep={currentStep} />
        <StepConfirmarOrden form={form} currentStep={currentStep} />
      </Form>
    </Modal>
  );
}

export default ModalForm;
