import React from "react"
import { InfoCircleOutlined } from "@ant-design/icons"
import { Input, Tooltip, Form } from "antd"
import { Row, Col } from "react-bootstrap"
import ShopTitle from "../ShopTitle"

export default function StepConfirmarOrden({ form, currentStep }) {
  const { getFieldValue } = form
  return (
    <div
      className="container-fluid"
      style={{ display: currentStep === 2 ? "block" : "none" }}
    >
      <Row className="m-step-content">
        <Col md>
          <ShopTitle title="Método de pago" />
          <p className="m-0">Banco: CHN (Crédito hipotecario nacional)</p>
          <p className="m-0">Cuenta monetaria No.: 010990836743</p>
          <p className="m-0">
            A nombre de: Siomara Patricia Balcarcel Garrido (Artista)
          </p>

          <p className="m-0 mt-3">Banco: BI (Industrial)</p>
          <p className="m-0">Cuenta monetaria No.: 2230046292</p>
          <p className="m-0">
            A nombre de: Inmer Omar Balcarcel Garrido (Representante artístico)
          </p>

          <Form.Item name="refPago">
            <Input
              className="m-0 mt-3 mb-1-md"
              placeholder="No. referencia pago y banco"
              suffix={
                <Tooltip title="Si ha realizado el depósito de inmediato, ingréselo aquí, de lo contrario puede colocar la orden y enviar el número de referencia al correo: spbalcarcelart@gmail.com, o llenar el formulario de contacto.">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col md>
          <ShopTitle title="Dirección de envío" />
          <p className="m-0">{getFieldValue("nombre")}</p>
          <p className="m-0">{getFieldValue("email")}</p>
          <p className="m-0">{getFieldValue("direccion")}</p>
          <ShopTitle title="Datos facturación" />
          <p className="m-0">
            {getFieldValue("nombreFacturacion") == null
              ? getFieldValue("nombre")
              : getFieldValue("nombreFacturacion")}
          </p>
          <p className="m-0">
            {getFieldValue("nit") == null ? "c/f" : getFieldValue("nit")}
          </p>
          <p className="m-0">
            {getFieldValue("direccionFacturacion") == null
              ? getFieldValue("direccion")
              : getFieldValue("direccionFacturacion")}
          </p>
        </Col>
        <Col md className="my-auto text-center">
          <h5>¡Gracias!</h5>
          <p>
            Por apreciar mi arte, espero que la obra le brinde momentos de
            felicidad, tranquilidad y reflexión. Para mi es un gusto y honor
            poder expresar mis sentimientos a través del arte.
          </p>
        </Col>
      </Row>
      <p className="m-0">
        *Después de confirmar la orden tiene 5 días para realizar el depósito.
      </p>
    </div>
  )
}
