import React from "react"
import { Input, Form } from "antd"
import { Row, Col } from "react-bootstrap"
import MyImg from "../MyImg"
import ShopTitle from "../ShopTitle"

const numberWithCommas = (num: Number) => {
  let parts = num.toString().split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return parts.join(".")
}

export default function StepDetalleCompra({ art, currentStep }) {
  const price = Number(String(art.price).replace(/\D/g, ""))
  return (
    <div
      className="container-fluid"
      style={{ display: currentStep === 0 ? "block" : "none" }}
    >
      <Row className="m-step-content">
        <Col sm className="my-auto">
          <Row>
            <Col sm>
              <MyImg name={art.img} alt={art.title} />
            </Col>
            <Col sm className="my-auto text-center p-2">
              {/* @ts-ignore-next-line */}
              <Form.Item hidden name="nombreObra" initialValue={art.title}>
                <Input disabled />
              </Form.Item>
              <h6>{art.title}</h6>
              <h6>{`Total: Q${numberWithCommas(price * 7.5)}.00*`}</h6>
            </Col>
          </Row>
        </Col>
        <Col sm className="my-auto">
          <div className="shadow p-3 rounded ">
            <ShopTitle title="Incluye" />
            <ul>
              <li>Obra original</li>
              <li>Certificado de autenticidad</li>
              <li>Enmarcado de la obra (marco de madera)</li>
              <li>Envío gratis a toda Guatemala</li>
            </ul>
          </div>
        </Col>
      </Row>
      <p>
        *Actualmente solo contamos con envíos a Guatemala, por lo que se muestra
        el precio en GTQ.
      </p>
    </div>
  )
}
