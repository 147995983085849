import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Stack, Typography } from '@mui/material';
import Layout from '../components/Layout';
import imgBackground from '../images/2.jpeg';
import ParalaxTitle from '../components/ParalaxTitle';
import SEO from '../components/seo';
import slugFormat from '../Utils/SlugFormat';
import SocialShareButtons from '../components/SocialShareButtons';
import generateImgAltText from '../Utils/GenerateImgAltText';
import ShopModal from '../components/ShopModal/ShopModal';
import MyImg from '../components/MyImg';

interface IArt {
  title: string;
  description: string;
  img: string;
  technique: string;
  year: string;
  dimensions: string;
  price: string;
  sold: number;
}

function ArtData({ title, text }: { title: string; text: string }) {
  return (
    <Typography style={{ textAlign: 'justify', whiteSpace: 'pre-line' }}>
      <strong>{title}</strong> {text}
    </Typography>
  );
}

export default function Product({ pageContext }) {
  const art = pageContext.node as IArt;
  return (
    <Layout>
      <SEO
        title={art.title}
        description={art.description}
        image={require(`../images/${art.img}`).default}
        path={`/galeria/${slugFormat(art.img)}/`}
      />
      <ParalaxTitle text={art.title} imgBackground={imgBackground} />
      <Container>
        <Row className="my-5">
          <div className="col-xl-5 col-lg-6 my-auto">
            <MyImg name={art.img} alt={generateImgAltText(art)} />
          </div>
          <Stack gap={2} className="col-xl-7 col-lg-6 my-auto">
            <ArtData title="Técnica:" text={art.technique} />
            <ArtData title="Año:" text={art.year} />
            {art.dimensions && <ArtData title="Dimensiones:" text={art.dimensions} />}
            {art.description && <ArtData title="Descripción:" text={art.description} />}
            {art.sold === 0 && <ArtData title="Precio:" text={art.price} />}
            <Typography>
              <strong>Compartir: </strong>
              <SocialShareButtons
                size={40}
                round
                style={{ paddingRight: '5px' }}
                url={`https://www.spbalcarcelart.com/galeria/${slugFormat(art.img)}`}
              />
            </Typography>
            {art.sold === 0 && <ShopModal art={art} />}
          </Stack>
        </Row>
      </Container>
    </Layout>
  );
}
