import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const MyImg = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile {
            edges {
              node {
                base
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const image = data.allFile.edges.find((edge) => {
          return edge.node.base === props.name
        })
        if (!image) {
          return <img alt={props.alt ? props.alt : props.name} />
        }
        const imageData = getImage(image.node)
        return <GatsbyImage image={imageData} {...props} />
      }}
    />
  )
}

export default MyImg
