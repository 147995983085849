import React from "react"
import { Input, Form } from "antd"
import { Row, Col } from "react-bootstrap"
import ShopTitle from "../ShopTitle"

export default function ColFacturacion() {
  return (
    <Col md className="p-0 pt-5">
      <ShopTitle title="Datos de facturación" />
      <Row>
        <Col md>
          <Form.Item name="nombreFacturacion">
            <Input placeholder="Nombre" />
          </Form.Item>
        </Col>
        <Col md>
          <Form.Item name="nit">
            <Input placeholder="NIT" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="direccionFacturacion">
        <Input placeholder="Dirección" />
      </Form.Item>
    </Col>
  )
}
