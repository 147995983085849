import React from "react"

export default function ShopTitle({ title }) {
  return (
    <>
      <h6 className="text-center title text-dark">{title}</h6>
      <hr
        style={{
          borderColor: "#343A40",
          maxWidth: "300px",
        }}
      ></hr>
    </>
  )
}
