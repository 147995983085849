import React from "react"
import { Input, Select, Form } from "antd"
import { Row, Col } from "react-bootstrap"
import ShopTitle from "../ShopTitle"
import ColFacturacion from "./ColFacturacion"

const { Option } = Select

interface StepDireccionEnvioProps {
  onCreate: () => void
  form: any
  currentStep: number
}

const rules = {
  nombre: [{ required: true, message: "Por favor ingresa tu nombre" }],
  direccion: [
    { required: true, message: "Por favor ingresa dirección de envío" },
  ],
}

export default function StepDireccionEnvio({
  onCreate,
  form,
  currentStep,
}: StepDireccionEnvioProps) {
  return (
    <div
      className="container-fluid"
      style={{ display: currentStep === 1 ? "block" : "none" }}
    >
      <Row className="m-step-content">
        <Col md>
          <Row>
            <Col md>
              <Row>
                <Col md>
                  <ShopTitle title=" Dirección de envío" />
                </Col>
              </Row>
              <Row>
                <Col md>
                  <Form.Item name="nombre" rules={rules.nombre}>
                    <Input placeholder="Nombre y apellido" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[
                      { type: "email", message: "El correo no es valido" },
                      {
                        required: true,
                        message: "Por favor ingresa tu correo electrónico",
                      },
                    ]}
                  >
                    <Input placeholder="Correo electrónico" />
                  </Form.Item>
                  <Form.Item name="telefono">
                    <Input placeholder="Número de teléfono" type="tel" />
                  </Form.Item>
                </Col>
                <Col md>
                  <Form.Item name="direccion" rules={rules.direccion}>
                    <Input.TextArea
                      rows={2}
                      placeholder="Dirección de entrega"
                      style={{ marginTop: "3.5px" }}
                    />
                  </Form.Item>
                  <Form.Item label="País" name="pais" initialValue="guatemala">
                    <Select>
                      <Option value="guatemala">Guatemala</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <ColFacturacion />
            </Col>
          </Row>
        </Col>
      </Row>
      <p>*El tiempo de envío estimado es de 2 semanas</p>
    </div>
  )
}
