import React from "react"

export default function NetlifyShopForm() {
  return (
    <form
      name="order"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      hidden
    >
      {/* You still need to add the hidden input with the form name to your JSX form */}
      <input type="hidden" name="form-name" value="order" />
      <input type="text" name="nombreObra" />
      <input type="text" name="nombre" />
      <input type="text" name="email" />
      <input type="text" name="telefono" />
      <input type="textarea" name="direccion" />
      <select name="pais">
        <option value="guatemala">Guatemala</option>
      </select>
      <input type="text" name="nombreFacturacion" />
      <input type="text" name="nit" />
      <input type="text" name="direccionFacturacion" />
      <input type="text" name="refPago" />
    </form>
  )
}
