import React, { useState } from 'react';
import { Button, Form, message } from 'antd';
import NetlifyShopForm from './NetlifyShopForm';
import CollectionCreateForm from './CollectionCreateForm';

const encode = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

export default function ShopModal(props) {
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();

  const showModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const next = () => setCurrentStep(currentStep + 1);
  const prev = () => setCurrentStep(currentStep - 1);
  const nextValidateForm = () => {
    form
      .validateFields()
      .then(() => next())
      .catch((errorInfo) => {
        console.log('errorInfo', errorInfo);
      });
  };

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'order', ...values }),
        })
          .then(() => {
            message.success('¡Felicidades! la orden ha sido creada exitosamente.', 5);
            form.resetFields();
            closeModal();
            setCurrentStep(0);
          })
          .catch((error) =>
            message.error(
              `Lo sentimos, no se ha podido crear la orden, prueba de nuevo en unos momentos. ${error}`,
              5
            )
          );
      })
      .catch((errorInfo) => {
        console.log('errorInfo', errorInfo);
      });
  };

  return (
    <div>
      <Button
        size="large"
        type="primary"
        onClick={showModal}
        style={{ width: '100%', fontSize: 'larger', height: '60px' }}
      >
        Comprar ahora
      </Button>
      <NetlifyShopForm />
      <CollectionCreateForm
        open={open}
        currentStep={currentStep}
        onCancel={closeModal}
        onCreate={handleCreate}
        next={next}
        prev={prev}
        nextValidateForm={nextValidateForm}
        form={form}
        {...props}
      />
    </div>
  );
}
